import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, notification, Input, Tag } from 'antd';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { codeclubs } from '../../../Files/centers';

const AllRegistrations = () => {
    const [registrations, setRegistrations] = useState([]);
    const [filteredRegistrations, setFilteredRegistrations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [api, contextHolder] = notification.useNotification();

    const centerMap = codeclubs.reduce((map, center) => {
        map[center.id] = center.name;
        return map;
    }, {});

    useEffect(() => {
        fetchRegistrations();
    }, []);

    const exportToExcel = () => {
        const dataToExport = registrations
            .flatMap((item) => (item.children ? item.children : item))
            .map((item) => ({
                'Child Name': item.childName,
                'Parent Name': item.parentsName,
                'Parent Contact': item.parentsContact,
                Center: centerMap[item.center] || item.center,
                Age: item.age,
                Gender: item.gender,
                Date: item.date,
            }));

        const worksheet = XLSX.utils.json_to_sheet(dataToExport);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Registrations');

        const excelBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'registrations.xlsx');
    };

    const fetchRegistrations = async () => {
        setLoading(true);
        try {
            const registrationsRef = collection(db, 'registrations');
            const snapshot = await getDocs(registrationsRef);
            const registrationsData = snapshot.docs.map((doc) => ({
                docId: doc.id,
                ...doc.data(),
            }));

            // Identify duplicates by childName and parentsContact
            const counts = {};
            registrationsData.forEach((reg) => {
                const key = `${reg.childName}-${reg.parentsContact}`;
                counts[key] = counts[key] ? counts[key] + 1 : 1;
                reg.duplicateKey = key; // Store the key for grouping
            });

            // Group registrations by duplicateKey
            const groupMap = {};
            registrationsData.forEach((reg) => {
                if (groupMap[reg.duplicateKey]) {
                    groupMap[reg.duplicateKey].push(reg);
                } else {
                    groupMap[reg.duplicateKey] = [reg];
                }
            });

            // Prepare data for the table
            const groupedData = [];
            Object.values(groupMap).forEach((group) => {
                if (group.length > 1) {
                    // Duplicates
                    groupedData.push({
                        key: group[0].duplicateKey,
                        isDuplicate: true,
                        ...group[0],
                        // Mark each child as a duplicate
                        children: group.map((reg) => ({ ...reg, key: reg.docId, isDuplicate: true })),
                    });
                } else {
                    // Unique records
                    groupedData.push({
                        key: group[0].docId,
                        isDuplicate: false,
                        ...group[0],
                    });
                }
            });

            // Sort duplicates first
            groupedData.sort((a, b) => b.isDuplicate - a.isDuplicate);

            setRegistrations(groupedData);
            setFilteredRegistrations(groupedData);
        } catch (error) {
            console.error('Error fetching registrations:', error);
        }
        setLoading(false);
    };

    const handleDelete = async (docId) => {
        try {
            await deleteDoc(doc(db, 'registrations', docId));
            api.success({
                message: 'Delete Successful',
                description: 'The registration has been deleted successfully.',
            });
            fetchRegistrations();
        } catch (error) {
            console.error('Error deleting registration:', error);
            api.error({
                message: 'Delete Failed',
                description: 'There was an error deleting the registration.',
            });
        }
    };

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);

        const filteredData = registrations.filter((item) => {
            const itemString = `${item.childName} ${item.parentsName} ${item.parentsContact} ${item.center}`.toLowerCase();
            const matchItem = itemString.includes(value);

            const matchChildren =
                item.children &&
                item.children.some((child) => {
                    const childString = `${child.childName} ${child.parentsName} ${child.parentsContact} ${child.center}`.toLowerCase();
                    return childString.includes(value);
                });

            return matchItem || matchChildren;
        });

        setFilteredRegistrations(filteredData);
    };

    const columns = [
        {
            title: 'Child Name',
            dataIndex: 'childName',
            key: 'childName',
            width: '12.5%',
            render: (text, record) => {
                const style = record.isDuplicate ? { backgroundColor: '#fffb8f' } : {};
                return <div style={style}>
                    {text}
                </div>;
            },
        },
        {
            title: 'Parent Name',
            dataIndex: 'parentsName',
            key: 'parentsName',
            width: '12.5%',
        },
        {
            title: 'Parent Contact',
            dataIndex: 'parentsContact',
            key: 'parentsContact',
            width: '12.5%',
        },
        {
            title: 'Center',
            dataIndex: 'center',
            key: 'center',
            width: '12.5%',
            render: (centerId) => centerMap[centerId] || centerId,
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
            width: '12.5%',
        },
        {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
            width: '12.5%',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '12.5%',
        },
        {
            title: 'Action',
            key: 'action',
            width: '12.5%',
            render: (_, record) => {
                if (record.isDuplicate && !record.children) {
                    return (
                        <Popconfirm
                            title="Are you sure you want to delete this registration?"
                            onConfirm={() => handleDelete(record.docId)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger type="primary">
                                Delete Duplicates
                            </Button>
                        </Popconfirm>
                    );
                } else {
                    return (
                        <Popconfirm
                            title="Are you sure you want to delete this registration?"
                            onConfirm={() => handleDelete(record.docId)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    )
                }
            },
        },
    ];

    return (
        <div className="all-registrations">
            {contextHolder}
            <h1>All Registrations</h1>
            <div style={{ display: 'flex', marginBottom: 16 }}>
                <Input
                    placeholder="Search registrations"
                    prefix={<SearchOutlined />}
                    value={searchText}
                    onChange={handleSearch}
                    style={{ marginRight: 16, width: 300 }}
                    allowClear
                />
                <Button type="primary" onClick={exportToExcel}>
                    Export All to Excel
                </Button>
            </div>
            <Table
                dataSource={filteredRegistrations}
                columns={columns}
                loading={loading}
                rowKey="key"
                pagination={{ pageSize: 50 }}
                expandable={{
                    expandedRowRender: (record) =>
                        record.children && (
                            <Table
                                dataSource={record.children}
                                columns={columns}
                                pagination={false}
                                rowKey="docId"
                                showHeader={false}
                            />
                        ),
                    rowExpandable: (record) => record.isDuplicate,
                }}
            />
        </div>
    );
};

export default AllRegistrations;