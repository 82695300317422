import React, { useEffect, useState } from 'react'

import { programs } from '../../../Files/programs'

import './styles.scss'
import { Select } from 'antd'

const months = ['All', 'October', 'November', 'December', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September']

const Training = () => {
    const [programsFiltered, setProgramsFiltered] = useState([])

    useEffect(() => {
        setProgramsFiltered(programs)
    }, [])

    const monthWithYear = (month) => {
        const monthsBeforeOctober = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'];
        const year = monthsBeforeOctober.includes(month) ? '2025' : '2024';
        return `${month} ${year}`;
    };

    return (
        <div className='training'>
            <div className='header'>
                <h1>Training</h1>
            </div>
            <div className='trins'>
                <div className='program'>
                    <h2>Microbit</h2>
                    <div className='contents'>
                        <div className='sinhala'>
                            <h3>Sinhala</h3>
                            <a href='https://drive.google.com/drive/folders/1j1DazzdPF0rg3s3UdAQdoeCL45r0jGRG' target='_blank' rel='noreferrer'>
                                Training Materials
                            </a>
                        </div>
                        <div className='english'>
                            <h3>English</h3>
                            <a href='https://drive.google.com/drive/folders/1GzMgo51pxM_fkj8ycTCmZ9iG6n9bnc4g' target='_blank' rel='noreferrer'>
                                Training Materials
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Training